import { AxiosResponse } from 'axios';

import { AxiosAPI } from "./api";

const FiltersAPI = ({ showServiceFeedbackModal }: any) => {

    const searchFiles = (fileName: string) => {
		const serviceUrl = 'documentsystem-api/Documents/SearchFiles';
		return AxiosAPI.get(serviceUrl,
            { params: { FileName: fileName } }
		).then((response: AxiosResponse) => response.data)
        .catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`));
	};

    const getPeips = () => {
		const serviceUrl = 'api/v1/Peip';
		return AxiosAPI.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`))
    };

    const getVehicles = () => {
        const serviceUrl = 'api/v1/Vehicle';
		return AxiosAPI.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`))
	};

    const getFundsByVehicle = (vehicleId?: number) => {
        const serviceUrl = '/funds-api/FundVehicle/GetFundsVehicles';
		return AxiosAPI.get(serviceUrl, {
            params: {
                IdElement: vehicleId ? vehicleId : undefined,
                Type: 'Vehicle'
            }
        })
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`))
    };

	const getAllFunds = () => {
		const serviceUrl = '/funds-api/FundVehicle/GetFundsVehicles?Type=Vehicle';
		return AxiosAPI.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`))
	}

    const getTags = () => {
		const serviceUrl = '/documentsystem-api/Tags/GetTags';
		return AxiosAPI.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`))
    };

    const getInvestorsFilterPowerBI = (contactId: string) => {
		const serviceUrl = `/masters-api/UsersInvestors/GetInvestorsByContactId/${contactId}`;
		return AxiosAPI.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`))
    };

	const getFilteredCouments = (filters: any) => {
		const serviceUrl = '/documentsystem-api/GetFilteredDocumentList';	
		return AxiosAPI.post(serviceUrl, filters)
			.then((response: AxiosResponse) => response.data)
			.catch((error) => showServiceFeedbackModal(error.response.status, `${serviceUrl}`))
	}

    return {
        searchFiles,
        getTags,
        getPeips,
        getVehicles,
        getFundsByVehicle,
        getInvestorsFilterPowerBI,
		getFilteredCouments,
		getAllFunds
    };
};

export default FiltersAPI;