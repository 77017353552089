import AnalysisAndReporting, {useAnalysisAndReporting,} from "@qualitas/reporting";

const CashflowReporting = (props: any) => {
  const { api, investorsFilterPowerBI } = props;

  const reportingPropsCashflows = useAnalysisAndReporting({
    api,
    hasMenu: false,
    appName: 'Track Wizard',
    powerBIName: "Cashflows Estimation",
  });

  return (
    <div className="cashflows-reporting">
      {investorsFilterPowerBI ? (
        <AnalysisAndReporting
          {...reportingPropsCashflows}
          filters={[
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "InstitutionName",
                column: "InstitutionLong",
              },
              operator: "In",
              values: investorsFilterPowerBI,
            },
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "vw_General_Inversores_Modificada2",
                column: "InstitutionLong",
              },
              operator: "In",
              values: investorsFilterPowerBI,
            },
          ]}
        />
      ): null }
    </div>
  );
};

export default CashflowReporting;
