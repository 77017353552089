import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import FilesViewer, {
  FilesViewerAPI,
  useFilesViewer,
} from "@qualitas/files-viewer";

import SearchNavBar from "../SearchNavBar/SearchNavBar";

import "../../../node_modules/@qualitas/files-viewer/dist/FilesViewer.scss";
import "./Documents.scss";
import FilteredDocuments from "../FilteredDocuments/FilteredDocuments";
import useSearchNavBar from "../../hooks/useSearchNavBar";
import FeedbackModal, { useFeedbackModal } from "@qualitas/feedback-modal";
import FiltersAPI from "../../api/FiltersAPI";

export interface IDocumentsProps {
  baseUrl?: string;
  getAuthToken: () => string;
  application: string;
  translations: any;
  language: string;
}

const Documents = ({
  baseUrl,
  getAuthToken,
  application,
  translations,
  language
}: IDocumentsProps) => {
  const feedbackModalProps = useFeedbackModal({});

  const filtersAPI = FiltersAPI({
    showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal,
  });

  const filesViewerAPI = FilesViewerAPI({
    baseUrl,
    getAuthToken,
    showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal,
  });

  const fileViewerModalProps = useFilesViewer({
    api: filesViewerAPI,
    appName: application,
    idLanguage: language === "en" ? 1 : 2,
  });

  const {
    fetching,
    options,
    valueSearch,
    isFileListOpen,
    hideOverflow,
    footerListText,
    peips,
    filteredVehicles,
    funds,
    filters,
    tagsList,
    tagRender,
    openFileList,
    closeFileList,
    handleSearch,
    handleFilterChange,
    onChangeFilters,
    onChangeTags,
    areFiltersActive,
    openPdfFile,
    onClearFilters,
    onSearchFilteredDocuments,
    onSearchText,
    filteredDocuments,
    isSearchMade,
    isLoading,
    setIsFileListOpen,
  } = useSearchNavBar({
    api: filtersAPI,
    application,
    onSelectFile: fileViewerModalProps.onSelectFile,
    openFileViewer: fileViewerModalProps.openPDFViewerModal,
  });

  return (
	<div>
    <Routes>
      <Route
        path="*"
        element={
          <div className="documents-viewer">
            <SearchNavBar
              showFilters
              application={application}
              peips={peips}
              filteredVehicles={filteredVehicles}
              funds={funds}
              filters={filters}
              handleFilterChange={handleFilterChange}
              onChangeFilters={onChangeFilters}
              isLoading={isLoading}
              tagsList={tagsList}
              onChangeTags={onChangeTags}
              openFile={(x: any) => {
                fileViewerModalProps.openFileByUrl(x.blobStorageURL);
              }}
              isFileListOpen={isFileListOpen}
              openFileList={openFileList}
              closeFileList={closeFileList}
              tagRender={tagRender}
              handleSearch={handleSearch}
              valueSearch={valueSearch}
              hideOverflow={hideOverflow}
              options={options}
              fetching={fetching}
              footerListText={footerListText}
              onClearFilters={onClearFilters}
              onSearchFilteredDocuments={onSearchFilteredDocuments}
              setIsFileListOpen={setIsFileListOpen}
            />
            {!areFiltersActive ? (
              <FilesViewer
                isLoading={fileViewerModalProps.isLoading}
                isLoadingDocument={fileViewerModalProps.isLoadingDocument}
                texts={{ ...translations, title: "" }}
                documentToken={fileViewerModalProps.documentToken}
                isPDFViewerOpen={fileViewerModalProps.isPDFViewerOpen}
                folderTree={fileViewerModalProps.folderTree}
                openPDFViewerModal={fileViewerModalProps.openPDFViewerModal}
                disableRightClick={fileViewerModalProps.disableRightClick}
                onSelectFile={fileViewerModalProps.onSelectFile}
                closePDFViewerModal={fileViewerModalProps.closePDFViewerModal}
                showMenu
                shouldExpandAll={fileViewerModalProps.shouldExpandAll}
                onExpandFolder={fileViewerModalProps.onExpandFolder}
                expandedKeys={fileViewerModalProps.expandedKeys}
                hideLoadingDocument={fileViewerModalProps.hideLoadingDocument}
              />
            ) : null}
            {areFiltersActive && (
              <FilteredDocuments
                onClearFilters={onClearFilters}
                filteredDocuments={filteredDocuments}
                isSearchMade={isSearchMade}
                loading={isLoading}
                onSearchText={onSearchText}
                fileViewerModalProps={fileViewerModalProps}
                openPdfFile={openPdfFile}
              />
            )}
          </div>
        }
      />
    </Routes>
      <FilesViewer
        documentToken={fileViewerModalProps.documentToken}
        isPDFViewerOpen={fileViewerModalProps.isPDFViewerOpen}
        disableRightClick={fileViewerModalProps.disableRightClick}
        closePDFViewerModal={fileViewerModalProps.closePDFViewerModal}
      />
      <FeedbackModal {...feedbackModalProps} />
	</div>
  );
};

export default Documents;
