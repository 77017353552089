import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageSelector from "./LanguageSelector";
import { IHomePageMenuProps } from "./IHomePage";

const HomePageMenu = ({ logout, setLanguage, language, userLogo }: IHomePageMenuProps) => {
  let logo;
  switch (userLogo) {
    case "https://qualitasfunds.blob.core.windows.net/assets/image-gallery/InstitutionPalette/GoldenWealth.png":
      logo =
        "https://qualitasfunds.blob.core.windows.net/assets/image-gallery/InstitutionPalette/GoldenWealthBlack.png";
      break;
    case "https://qualitasfunds.blob.core.windows.net/assets/image-gallery/SingularWhiteLogo.png":
      logo =
        "https://qualitasfunds.blob.core.windows.net/assets/image-gallery/SingularLogoBlack.png";
      break;
    default:
      logo =
        "https://qualitasfunds.blob.core.windows.net/assets/image-gallery/blackLogo.png";
      break;
  }

  return (
    <div className="homePage-navbar">
      <img alt="qualitasLogo" src={logo} />
      <div className="homePage-navbar-user">
        <div className="homePage-navbar-extracontent">
          <LanguageSelector setLanguage={setLanguage} language={language} />
          <button className="logout-btn" onClick={logout}>
            <FontAwesomeIcon fontSize={20} icon={faRightFromBracket} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePageMenu;
