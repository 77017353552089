import { CaretRightOutlined, DownloadOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, Skeleton, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import { IGenerateDocumentsProps } from "./IDocuments";
import useDocumentGeneration from "../../../hooks/useDocumentGeneration";
import ReportTypes from "../../../enums/ReportTypes";

const { Option } = Select;

const GenerateDocuments = (props: IGenerateDocumentsProps) => {
  const {
    translations,
    language,
    mainColor,
    investorsList,
    handleInvestorsCheckbox,
    selectedInvestors,
    isTableLoading,
  } = props;

  const {
    handleChangeReport,
    reportTypeSelected,
    documentLoading,
    setSelectedDate,
    capitalCallDates,
    distributionDates,
    viewReport,
    selectedDate
  } = useDocumentGeneration({ selectedInvestors, language });

  //No mostrar Cartas Compromiso en ambiente de Pre-Producción
  let isPre = window.location.href.includes("-pre");

  const downloadFakePdf = () => {
	const link = document.createElement("a");
	link.href = "https://qualitasfunds.blob.core.windows.net/assets/attachments-assets/Acuerdo Suscripción.pdf";
	link.download = "Acuerdo Suscripción.pdf";
	link.target = "_blank";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
  }

  const renderClosing = () => (
    <>
      <div className="generate-item">
        <p>{translations.enFecha}:</p>
        <DatePicker disabled />
      </div>
      <Button
        loading={documentLoading}
        onClick={() => isPre ? downloadFakePdf() : viewReport("Closing")}
        icon={<DownloadOutlined />}
        className="open-document-button"
        disabled={selectedInvestors.investors.length === 0}
      >
        {translations.abrirDocumento}
      </Button>
    </>
  );

  const renderCapitalAccount = () => (
    <>
      <div className="generate-item">
        <p>{translations.enFecha}:</p>
        <DatePicker
          disabledDate={(current: any) =>
            current && current > dayjs().endOf("day")
          }
          placeholder={translations.enFecha}
          value={selectedDate !== "" ? dayjs(selectedDate, 'DD/MM/YYYY') : undefined}
          format="DD/MM/YYYY"
          onChange={(val: any) =>
            setSelectedDate(dayjs(val).format("DD/MM/YYYY"))
          }
        />
      </div>
      <Button
        className="open-document-button"
        loading={documentLoading}
        disabled={selectedInvestors.investors.length === 0}
        onClick={() => viewReport("CapitalAccount")}
        icon={<DownloadOutlined />}
      >
        {translations.generar}
      </Button>
    </>
  );

  const renderCapitalCall = () => (
    <>
      <div className="generate-item">
        <p>{translations.enFecha}:
          <Tooltip title={translations.fechasCombinadas} placement="right">
            <span> <InfoCircleOutlined style={{color: '#8fac10'}}/></span>
          </Tooltip>
        </p>
          <Select
            placeholder={translations.enFecha}
            onChange={(val: any) => setSelectedDate(val)}
            value={selectedDate !== "" ? selectedDate : undefined}
          >
            {capitalCallDates.map((x: any, index: number) => (
              <Option key={index} value={x}>{dayjs(x, 'YYYY/MM/DD').format('DD/MM/YYYY')}</Option>
            ))}
          </Select>
      </div>
      <Button
        className="open-document-button"
        loading={documentLoading}
        disabled={capitalCallDates.length === 0 || selectedInvestors.investors.length === 0 || selectedDate === ""}
        onClick={() => viewReport("CapitalCalls")}
        icon={<DownloadOutlined />}
      >
        {translations.generar}
      </Button>
    </>
  );

  const renderDistribution = () => (
    <>
      <div className="generate-item">
        <p>{translations.enFecha}:
          <Tooltip title={translations.fechasCombinadas} placement="right">
            <span> <InfoCircleOutlined style={{color: '#8fac10'}}/></span>
          </Tooltip>
        </p>
        <Select
          placeholder={translations.enFecha}
          onChange={(val: any) => setSelectedDate(val)}
          value={selectedDate !== "" ? selectedDate : undefined}
        >
          {distributionDates.map((x: any, index: number) => (
            <Option key={index} value={x}>{dayjs(x, 'YYYY/MM/DD').format('DD/MM/YYYY')}</Option>
            ))}
        </Select>
      </div>
      <Button
        className="open-document-button"
        onClick={() => viewReport("Distribution")}
        icon={<DownloadOutlined />}
        loading={documentLoading}
        disabled={distributionDates.length === 0 || selectedInvestors.investors.length === 0 || selectedDate === ""}
      >
        {translations.generar}
      </Button>
    </>
  );

  return (
    <Spin
      size="large"
      spinning={isTableLoading}
      indicator={<LoadingOutlined style={{ fontSize: 46, color: mainColor, marginTop: '60px' }} />}
    >
      <div className="generate-documents-container">
        <h2>{translations.generarDocumentos}</h2>
        <div className="generate-documents-table">
          <div className="generate-documents-table-section">
            <h4>{translations.seleccioneMismoVehiculo}: </h4>
            <table className="investors-table">
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "90%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={2}>{translations.invesor}</th>
                </tr>
              </thead>
              <tbody>
                {investorsList.map((item: any, index: number) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="program-name" colSpan={2}>
                          <span>
                            <CaretRightOutlined />
                          </span>
                          {item.vehicle}
                        </td>
                      </tr>
                      {item.investors.map((investor: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="investment-name">
                              <input
                                type="checkbox"
                                checked={selectedInvestors.investors.includes(
                                  investor.idInvestor
                                )}
                                onChange={(e) =>
                                  handleInvestorsCheckbox(
                                    e.target.checked,
                                    item.idVehicle,
                                    investor.idInvestor
                                  )
                                }
                              />
                            </td>
                            <td>{investor.investorName}</td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
              </tbody>

          </table>
            {(isTableLoading && investorsList.length === 0) && <Skeleton active style={{ width: "100%" }} />}
          </div>
          <div className="generate-documents-form">
            <h4>{translations.seleccioneFormulario}: </h4>
            <div className="investor-document-form">
              <div className="generate-item">
                <p>{translations.docs}: </p>
                <Select
                  placeholder={translations.documentosInversores}
                  onChange={handleChangeReport}
                  value={reportTypeSelected.IdReport}
                  style={{ width: "300px" }}
                >
                  {ReportTypes.map((x: any) => {
					if(x.Name === "Anuncio") return null
					//if(isPre && x.Name === "CartaCompromiso") return null
                      return (
                        <Option
                          key={x.IdReport}
                          value={x.IdReport}
                          style={{ fontSize: "13px" }}
                        >
                          {translations[x.Description]}
                        </Option>
                      )
                  })}
                </Select>
              </div>
                  {reportTypeSelected.Name === "CartaCompromiso" &&
                    renderClosing()}
                  {reportTypeSelected.Name === "CertificadoParticipacion.rpt" &&
                    renderCapitalAccount()}
                  {reportTypeSelected.Name === "SolicitudDesembolso.rpt" &&
                    renderCapitalCall()}
                  {reportTypeSelected.Name === "NotificacionDistribucion.rpt" &&
                    renderDistribution()}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default GenerateDocuments;
