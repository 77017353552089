import ReportViewer, { useReporting, ReportViewerAPI } from '@qualitas/reportviewer';
import { AxiosAPI } from '../../../api/api';
import LanguageSelector from '../../MenuNavbar/LanguageSelector';
import { useLocation, useNavigate } from 'react-router-dom';

const Reports = (props: any) => {

	const {
		language,
		setLanguage,
		mainColor,
	  } = props;
  
	  const location = useLocation();
	  const navigate = useNavigate();	
	  const api = ReportViewerAPI({ api: AxiosAPI });
	const isBackoffice = false;

	const reportingProps = useReporting({ language, api, navigate, location, isBackoffice });

	return (
		<ReportViewer
			{...reportingProps}
			language={language}
			setLanguage={setLanguage}
			mainColor={mainColor}
			LanguageSelector={() => (
				<LanguageSelector
					language={language}
					setLanguage={setLanguage}
				/>
			)}
		/>
	);
};

export default Reports;
