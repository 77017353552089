import Navbar from '@qualitas/navbar';
import LanguageSelector from './LanguageSelector';
import { IMenuNavbarProps } from './IHomePage';

const MenuNavbar = (props: IMenuNavbarProps) => {
	const {
		logout,
		language, 
		setLanguage,
		navbarProps,
		menuItems,
	} = props;

	return (
		<Navbar
			{...navbarProps}
			userName={navbarProps.menuName ?? ''}
			logout={logout}
			menuItems={menuItems}
			reportIncident={()=> console.log(('report'))}
			isBackoffice={false}
			extraContent={
				<LanguageSelector setLanguage={setLanguage} language={language} />
			  }
	  
		/>
	);
};

export default MenuNavbar;
