import { Button, List, Modal, Spin, Tag } from 'antd';
import './FilteredDocuments.scss';
import { CaretLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import PdfIcon from '../../assets/PdfIcon';
import dayjs from 'dayjs';
import ExcelIcon from '../../assets/ExcelIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { IFilteredDocumentsProps } from './IFilteredDocuments';

const { Item } = List;

const FilteredDocuments = (props: IFilteredDocumentsProps) => {
	const { 
    onClearFilters, 
    filteredDocuments,
    isSearchMade,
    loading, 
    onSearchText, 
    openPdfFile, 
    fileViewerModalProps
} = props;

	return (
        <>
            <Modal
                centered
                className="modal-pdf-viewer"
                open={fileViewerModalProps.isPDFViewerOpen}
                onOk={fileViewerModalProps.closePDFViewerModal}
                onCancel={fileViewerModalProps.closePDFViewerModal}
                footer={[]}
                width="100%"
                destroyOnClose
            >
                <object
                    data={fileViewerModalProps.documentToken}
                    width="100%"
                    height="100%"
                    aria-label="PDF Viewer"
                />
            </Modal>
            <div className="filtered-documents">
			<Spin indicator={<LoadingOutlined style={{ fontSize: 34, color: 'black' }} spin />} spinning={loading}>
			<Button className='go-back-btn' onClick={onClearFilters}><CaretLeftOutlined />  Go back</Button>
                {(filteredDocuments?.length === 0 && !isSearchMade) && 
                    <div className="filtered-documents-img">
                        <Spin size="large" spinning={loading}>
                            <img src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/backoffice/browsing.png" alt="empty" />
                            <p>Please, to initiate the search, complete the necessary filters and click on the search button</p>
                        </Spin>
                    </div>
                }
                {(isSearchMade) &&
                <div className="filtered-documents-list">
                    <div className="filtered-documents-input">
                        <input onChange={(e: any) => onSearchText(e.target.value)} placeholder='SEARCH BY URL' />
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                    <div className="filtered-documents-columns">
                        <p>Size</p>
                        <p>File date</p>
                        <p>Last update</p>
                    </div>
                    <List
                        itemLayout="horizontal"
                        size='small'
                        pagination={{ position: 'bottom', align: 'end' }}
                        dataSource={filteredDocuments}
                        renderItem={(item: any, index: number) => (
                        <Item key={index} onClick={() => openPdfFile(item.blobStorageUrl)} className="filtered-documents-item">
                        <Item.Meta
                            avatar={(item.fileExtension === 'pdf' || item.fileExtension === 'PDF') ? <PdfIcon /> : <ExcelIcon />}
                            title={item.fileName}
                            description={item.folderPath}
                        />
                        <div className="filtered-file-data">
                            <div className="filtered-file-tags">
                            {item.tags?.length > 0 && item.tags.map((tag: any, indx: number) => {
                                if(tag){
                                    return (
                                        <Tag
                                            key={indx}
                                            color={tag.color ?? '#8fac10'}
                                            style={{ marginRight: 3 }}
                                        >
                                            {tag.name}
                                        </Tag>
                                    )
                                }
                                return null;
                                })} 
                            </div>
                            <p>{item.fileSize ? Math.trunc(item.fileSize/1000) : 0} KB</p>
                            <p>{dayjs(item.date).format('DD/MM/YYYY')}</p>
                            <p>{dayjs(item.fileCreationDate).format('DD/MM/YYYY')}</p>
                        </div>
                        </Item>
                    )}
                    />
                
                </div>

                }
				</Spin>
			</div>
        </>
	);
};

export default FilteredDocuments;
