import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import FeedbackModal, { useFeedbackModal } from '@qualitas/feedback-modal';
import { AnalysisAndReportingAPI } from '@qualitas/reporting';
import { PrivateRoute } from '@qualitas/auth';

import { Footer } from '@qualitas/navbar';
import { NavbarApi, useNavbar } from '@qualitas/navbar';

import HomePage from "./Components/MenuNavbar/HomePage";
import Documents from "./Components/Documents/Documents";
import MenuNavbar from "./Components/MenuNavbar/MenuNavbar";
import PortfolioDetailsReporting from "./Components/Reporting/PortfolioDetailsReporting";
import CashflowReporting from "./Components/Reporting/CashflowReporting";
import GenerateDocumentsContainer from "./Components/Documents/GenerateDocuments/GenerateDocumentsContainer";
import AppSelector from "./Components/AppSelector/AppSelector";
import Reports from "./Components/DocumentsGenerator/Reports/Reports";

import FiltersAPI from "./api/FiltersAPI";

import useSearchNavBar from "./hooks/useSearchNavBar";
import useApp from "./hooks/useApp";

import { IPrivateContentLayout } from "./IPrivateContentLayout";

import { languages } from "./assets/languages";

const PrivateContentLayout = ({
	baseUrl,
	getAuthToken,
	userIdInstitution,
	userContactId,
	userLogo,
	logOut,
	authProps, 
	navigate,
	language, 
	setLanguage,
	validateAuthToken,
	userPrimaryColor
}: IPrivateContentLayout) => {
	const [application, setApplication] = useState<string>();
	const [investorsFilterPowerBI, setInvetorsFilterPowerBI] = useState<string[]>();
	const [translations, setTranslations]: any = useState([]);
	let location = useLocation();

	useEffect(() => {
		setApplication(getApplication());
	}, [location.pathname]);
	
	const getApplication = () => {
		const app = location.pathname.split('/').reverse()[0];
		switch (app) {
			case 'InvestmentDocuments':
				return 'Investment Documents';
			case 'InvestorPortal':
				return 'Investor Portal';
			case 'Payments':
				return 'Payments';
			case 'DataRoom':
				return 'Data Room';
		};
	};

	const feedbackModalProps = useFeedbackModal({});

	const filtersAPI = FiltersAPI({ 
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});

	useEffect(() => {
		if (userContactId)
			filtersAPI.getInvestorsFilterPowerBI(userContactId)
				.then((investors: string[]) => setInvetorsFilterPowerBI(investors))
	}, [userContactId]);

	useEffect(() => {
		switch (language) {
			case 'es': setTranslations(languages.es);
				break;
			case 'en': setTranslations(languages.en);
				break;
		}
	}, [language]);

	const {
		fetching,
		options,
		valueSearch,
		isFileListOpen,
		hideOverflow,
		footerListText,
		peips,
		filteredVehicles,
		funds,
		filters,
		tagsList,
		selectedTags,
		tagRender,
		openFileList,
		closeFileList,
		handleSearch,
		handleFilterChange,
		onChangeFilters,
		onChangeTags
	} = useSearchNavBar({ api: filtersAPI, application });

	const reportingAPI = AnalysisAndReportingAPI({
		baseUrl,
		getAuthToken,
	});
	
	const { menuData, mainColor, inLineStyle
	} = useApp({ idInstitution: userIdInstitution, userPrimaryColor});

	let migratedUrl = process.env.REACT_APP_InvestorPortal
	let notMigratedUrl = process.env.REACT_APP_ReportingUrl

	const idSystemMaster = 7;
	const apiNavbar = NavbarApi(baseUrl, getAuthToken);
	const { menuItems, ...NavbarProps } = useNavbar({
		api: apiNavbar,
		idSystemMaster,
		userData: {...authProps},
		migratedUrl,
		notMigratedUrl,
		navigate,
		sendResetPasswordEmail: () => authProps.sendResetPasswordEmail(),
		language
	});

	return (
		<div className="layout" style={ inLineStyle }>
			{(location.pathname !== '/' && !location.pathname.includes("/Report/")) &&
			 <MenuNavbar 
				logout={logOut}
				language={language}
				setLanguage={setLanguage}
				menuItems={menuItems}
				navbarProps={NavbarProps}
			/>
		}
			<Routes>
				<Route element={<PrivateRoute validateAuthToken={validateAuthToken}/>}>
					<Route path="/" element={
						<HomePage 
							menuItems={menuItems} 
							logout={logOut} 
							language={language} 
							setLanguage={setLanguage} 
							translations={translations}
							menuData={menuData}
							userLogo={userLogo}
							userPrimaryColor={userPrimaryColor}
							/>
					}/>
						<Route 
							path ="/Report/:id?"
							element={
								<Reports
									language={language}
									setLanguage={setLanguage}
									mainColor={mainColor} 
								/>
							}
						/>
					<Route path="PortfolioDetail/Dynamic" element={
						<PortfolioDetailsReporting 
							api={reportingAPI}
						/>
					}/>
					<Route path="/Cashflows" element={
						<CashflowReporting
							api={reportingAPI}
							investorsFilterPowerBI={investorsFilterPowerBI}
						/>
					}/>
					<Route path="/Documents/View" element={
						<AppSelector />
					}/>
					<Route path="Documents/Generate" element={
						<GenerateDocumentsContainer
							translations={translations}
							mainColor={mainColor}
							language={language}
						/>
					}/>
					<Route path="/Documents/View/*" element={
						<>
							{application
								? <Documents
									translations={translations}
									baseUrl={baseUrl}
									getAuthToken={getAuthToken}
									application={application}
									language={language}
								/>
								: null 
							}
						</>
					}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Route>
			</Routes>
			<Footer language={language} />
			<FeedbackModal {...feedbackModalProps}/>
		</div>
	);
};

export default PrivateContentLayout;