import { RightOutlined } from '@ant-design/icons';
import { IAppCardProps } from './IAppCard';

const AppCard = (props: IAppCardProps) => {
	const { icon, color, firstWord, secondWord, description, onClick } = props;
	return (
		<button className="app-card" onClick={onClick} >
			<div className="app-card-text">
				<div className={`app-card-icon ${color}`}>{icon}</div>
				<p className="app-card-title">
					<strong className={color}>{firstWord}</strong> {secondWord}
				</p>
        		<p>{description}</p>
			</div>
			<p className="app-card-arrow">
				<RightOutlined />
			</p>
		</button>
	);
};

export default AppCard;
