import { Button, DatePicker, Select } from "antd";
import Filter from "./../Filters/Filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { IFiltersNavBar } from "./ISearchNavBar";

const { RangePicker } = DatePicker;

const FiltersNavBar = (props: IFiltersNavBar) => {
  const {
    onChangeFilters,
    filters,
    isLoading,
    filteredVehicles,
    peips,
    funds,
    onChangeTags,
    tagsList,
    tagRender,
    onClearFilters,
    onSearchFilteredDocuments,
    application,
  } = props;

  return (
    <div className="document-management-filters">
      {application === "Investment Documents" && (
        <div className="filter-opt-container">
          <label>{`Select Tags:`}</label>
          <div className="selector-col">
            <Select
              className="selector"
              placeholder={`Search by Tags:`}
              mode="tags"
              allowClear
              style={{ width: 180 }}
              disabled={isLoading}
              tagRender={tagRender}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value: any) => onChangeTags(value)}
              options={tagsList}
            />
          </div>
        </div>
      )}
      <div className="filter-opt-container">
        <label>Select Range:</label>
        <div className="selector-col">
          <RangePicker
            format="DD/MM/YYYY"
            disabled={isLoading}
            onChange={(e: any) => onChangeFilters(e, "dates")}
          />
        </div>
      </div>
      <Filter
        onChangeFilters={onChangeFilters}
        label={"Language"}
        value={filters.Language}
        options={[
          { label: "All", value: 0 },
          { label: "English", value: 1 },
          { label: "Spanish", value: 2 },
        ]}
        isLoading={isLoading}
      />
      <Filter
        onChangeFilters={onChangeFilters}
        label={"Peip"}
        value={filters.Peip}
        options={peips}
        isLoading={isLoading}
      />
      <Filter
        onChangeFilters={onChangeFilters}
        label={"Vehicle"}
        value={filters.Vehicle}
        options={filteredVehicles}
        isLoading={isLoading}
        disabled={!filters.Peip}
      />
      {application === "Investment Documents" && (
        <Filter
          onChangeFilters={onChangeFilters}
          label={"Fund"}
          value={filters.Funds}
          options={funds}
          isLoading={isLoading}
          tags
        />
      )}
      <div className="btns-search">
        <Button
          type="primary"
          onClick={onSearchFilteredDocuments}
          icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          loading={isLoading}
        >
          Search
        </Button>
        <Button onClick={onClearFilters} type="text">
          <p>Clear</p>
        </Button>
      </div>
    </div>
  );
};

export default FiltersNavBar;
