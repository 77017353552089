import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import { ConfigProvider } from "antd";

import Login, { AuthAPI, useAuth } from '@qualitas/auth';

import { initAPIs } from "./api/api";

import PrivateContentLayout from "./PrivateContentLayout";

import './App.scss';
import '../node_modules/@qualitas/auth/dist/Auth.scss';
import '../node_modules/@qualitas/reporting/dist/AnalysisAndReporting.scss';

const appName = 'Track Wizard';
const baseUrl = process.env.REACT_APP_NEW_WEB_API;

const App = () => {
	const [language, setLanguage] = useState<string>('es');
	const [searchParams, _] = useSearchParams();

	const navigate = useNavigate();

	useEffect(() => {
		if (searchParams.has('lang')) {
			setLanguage(searchParams.get('lang') as string);
		}
	}, [searchParams]);

	const authAPI = AuthAPI(baseUrl);
	const authProps = useAuth({
		api: authAPI,
		navigate,
		redirectUrl: '/',
		idSystemMaster: 7,
		systemMasterName: appName,
		redirectPassResetUrl: process.env['REACT_APP_Pass_Reset'],
		language
	});

	useEffect(() => {
		initAPIs(authProps.getAuthToken);
	}, []);

	return (
		<ConfigProvider
			theme={{
			token: {
				colorPrimary: "#B3D615",
				colorPrimaryText: "#000000",
				borderRadius: 0,
			},
			}}
		>
			<Routes>
				<Route path="/login" element={<Login {...authProps} appName={appName}/>}/>
					<Route path="*" element={
						<PrivateContentLayout
							baseUrl={baseUrl}
							getAuthToken={authProps.getAuthToken}
							userIdInstitution={authProps.userIdInstitution}
							userName={authProps.userName}
							userLogo={authProps.userLogo}
							userContactId={authProps.userContactId}
							logOut={authProps.logOut}
							validateAuthToken={authProps.validateAuthToken}
							authProps={authProps}
							navigate={navigate}
							language={language}
							setLanguage={setLanguage}
							userPrimaryColor={authProps.userPrimaryColor}
						/>
					}/>
			</Routes>
		</ConfigProvider>
	);
}

export default App;
