import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tag } from 'antd';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';

import { DocumentManagementFilters } from '../Models/DocumentManagementFilters';
import { Vehicle } from '../Models/Vehicle';

const useSearchNavBar = ({ api, application, onSelectFile, openFileViewer }: any) => {
	const [fetching, setFetching] = useState<boolean>(false);
	const [isFileListOpen, setIsFileListOpen] = useState<boolean>(false);
	const [hideOverflow, setHideOverflow] = useState<boolean>(true);
	const [options, setOptions] = useState([]);
	const [valueSearch, setValueSearch] = useState<string>('');
	const [footerListText, setFooterListText] = useState<string>('Please enter text');

	const [peips, setPeips] = useState<[]>([]);
	const [vehicles, setVehicles] = useState<Vehicle[]>([]);
	const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([]);
	const [funds, setFunds] = useState<any>([]);
	const [filters, setFilters] = useState<DocumentManagementFilters>({
		Language: 0,
	});

	const [tags, setTags] = useState<any>([]);
	const [tagsList, setTagsList] = useState<any>([]);
	const [selectedTags, setSelectedTags] = useState<number[]>([]);

	const [areFiltersActive, setAreFiltersActive] = useState<boolean>(false);
	const [filteredDocumentsComplete, setFilteredDocumentsComplete] = useState<any[]>([]);
	const [filteredDocuments, setFilteredDocuments] = useState<any[]>([]);
	const [isSearchMade, setIsSearchMade] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		getTags();
		getPeips();
		getVehicles();
		getAllFunds();
	}, []);

	const getTags = async() => {
		api.getTags()
			.then((tags: any) => {
				const tagsMapped = tags.map((x:any) => {
					let tag = {value: '', key: 0, label: ''} 
					tag.value = x.color
					tag.key = x.id
					tag.label = x.name
					return tag
				})
				setTagsList(tagsMapped);
				setTags(tags);
			})
	};

	const getPeips = () => {
		api.getPeips()
			.then((res: any) => {
				setPeips(res);
			});
	};

	const getVehicles = () => {
		api.getVehicles()
			.then((res: any) => {
				setVehicles(res);
			});
	};

	const getFundsByVehicle = (vehicleId?: number) => {
		api.getFundsByVehicle(vehicleId)
			.then((funds: any) => {
				setFunds(funds);
			})
	};

	const getAllFunds = () => {
		api.getAllFunds()
			.then((funds: any) => {
				setFunds(funds);
			})
	};

	const filterVehicles = (idPeip: number) => {
		let filtered = vehicles.filter((x: any) => x.idPeip === idPeip);
		setFilteredVehicles(filtered);
	};

	const filterFunds = (idVehicle: number) => {
		getFundsByVehicle(idVehicle);
	};

	const handleFilterChange = (newFilters: DocumentManagementFilters) => setFilters(newFilters);

	const onChangeFilters = (e: any, name: string) => {
		!areFiltersActive && setAreFiltersActive(true);
		if (name === 'Peip') {
			setFilters({
				...filters,
				Peip: e,
				Vehicle: undefined,
				Funds: [],
			});
			filterVehicles(e);
		} else if (name === 'Vehicle') {
			setFilters({
				...filters,
				Vehicle: e,
				Funds: [],			
			});
			if (application === 'Investment Documents') {
				filterFunds(e);
			}
		} else if (name === 'Fund') {
			setFilters({
				...filters,
				Funds: e,
			});
		} else if (name === 'Language') {
			setFilters({
				...filters,
				Language: e,
			});
		} else if (name === 'dates'){
			if(e){
				const [startRange, endRange] = e;
				setFilters({
					...filters,
					StartDate: dayjs(new Date(startRange)).format('YYYY-MM-DD'),
					EndDate: dayjs(new Date(endRange)).format('YYYY-MM-DD')
				})
			} else{
				const { StartDate, EndDate, ...newFilters } = filters;
				setFilters(newFilters);
			}
		}
	};

	const searchDocuments = useCallback(async (value: string) => {
		if (!value) {
			setOptions([]);
		}
		return await api.searchFiles(value);
	}, []);

	const handleSearch = (e: any) => {
		!isFileListOpen && setIsFileListOpen(true);
		setValueSearch(e);
		if (!e) {
			setHideOverflow(true);
			setOptions([]);
			setFetching(false);
			setFooterListText('Please enter text');
		}
		debounceFetcher(e);
	};

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value: any) => {
			setOptions([]);
			if (!value) {
				setFetching(false);
				return;
			}
			setFetching(true);
			searchDocuments(value).then((newOptions: any) => {
				if (newOptions && newOptions?.length > 0) {
					setOptions(newOptions);
					setHideOverflow(false);
				} else {
					setFooterListText('No files were found');
					setOptions([]);
				}
				setFetching(false);
			});
		};
		return debounce(loadOptions, 800);
	}, [searchDocuments]);

	const tagRender = (props: any) => {
		const { label, value } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color={value}
				onMouseDown={onPreventMouseDown}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		);
	};

	const onChangeTags = (e: any) => {
		!areFiltersActive && setAreFiltersActive(true);
		let filteredTags = e.map((x:any)=> {
			let foundTag = tags.find((y:any)=> y.color === x)
			return foundTag.id
		})
		setSelectedTags(filteredTags);
	};

	const openFileList = () => setIsFileListOpen(true);
	const closeFileList = () => setIsFileListOpen(false);

	const onSearchFilteredDocuments = async () => {
		setIsSearchMade(true);
		!areFiltersActive && setAreFiltersActive(true);
		setIsLoading(true);
		let data = {
			appName: application,
			...(filters.Language ? { idLanguage: filters.Language } : {}),
			...(filters.Funds ? { funds: filters.Funds } : {}),
			...(filters.Peip ? { idPeip: filters.Peip } : {}),
			...(filters.Vehicle ? { idVehicle: filters.Vehicle } : {}),
			...(selectedTags.length > 0 ? { tags: selectedTags } : {}),
			...(filters.StartDate ? { startDate: filters.StartDate } : {}),
			...(filters.EndDate ? { endDate: filters.EndDate } : {}),
		}

		await api.getFilteredCouments(data)
		.then((data: any) => {
			setFilteredDocumentsComplete(data)
			setFilteredDocuments(data)

		})
		setIsLoading(false)
	}

	const openPdfFile = (filePath: string) => {
		onSelectFile(filePath);
		openFileViewer();
	};

	const onClearFilters = () => {
		setFilters({});
		setSelectedTags([]);
		setAreFiltersActive(false);
		setFilteredDocuments([]);
		setIsSearchMade(false);
		getAllFunds()
	}

	const onSearchText = (e: string) => {
		if(e === ''){
			setFilteredDocuments(filteredDocumentsComplete);
			return;
		}
		let list = [...filteredDocumentsComplete]
		let filtered = list.filter((x: any) => x.folderPath.toLocaleLowerCase().includes(e.toLocaleLowerCase()))
		setFilteredDocuments(filtered);
	}


	return {
		fetching,
		options,
		valueSearch,
		isFileListOpen,
		hideOverflow,
		footerListText,
		peips,
		filteredVehicles,
		funds,
		filters,
		tagsList,
		selectedTags,
		tagRender,
		openFileList,
		closeFileList,
		handleSearch,
		handleFilterChange,
		onChangeFilters,
		onChangeTags,
		areFiltersActive,
		openPdfFile,
		onClearFilters,
		onSearchFilteredDocuments,
		onSearchText,
		filteredDocuments,
		isSearchMade,
		isLoading,
		setIsFileListOpen
	};
};

export default useSearchNavBar;
