import { Select } from 'antd';
import { IFilterProps } from './IFilter';
const { Option } = Select;

const Filter = ({
	onChangeFilters,
	label,
	value,
	options,
	isLoading,
	disabled,
	tags
}: IFilterProps) => (
	<div className="filter-opt-container">
		<label>{`Select ${label}:`}</label>
		<div className="selector-col">
			<Select
				disabled={isLoading || disabled}
				mode={tags ? 'tags' : undefined}
				value={value}
				style={{width: 180}}
				onChange={
					label === 'Application'
						? onChangeFilters
						: (value: number) => onChangeFilters(value, label)
				}
				className="selector"
                placeholder={`Select ${label}:`}
				allowClear
                showSearch
				maxTagCount={'responsive'}
                filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
				}
			>
                {label === 'Peip'
					? options.map((x: any) => (
						<Option key={x.idPeip} value={x.idPeip} label={x.peipNameLong}>
						{x.peipNameLong}
						</Option>
					))
					: null
				}

                {label === 'Vehicle'
					? options.map((x: any) => (
						<Option key={x.idVehicle} value={x.idVehicle} label={x.category}>
							{x.category}
						</Option>
                	))
					: null
				}

                {label === 'Fund'
					? options.map((x: any) => (
						<Option key={x.idQFundInvest_Peip_Fund} value={x.idFund} label={x.fundName}>
							{x.fundName}
						</Option>
                	))
					: null
				}

                {(label === 'Language' || label === 'Application')
					? options.map((x: any) => (
						<Option key={x.value} value={x.value} label={x.label}>
							{x.label}
						</Option>
					))
					: null
				}

			</Select>
		</div>
	</div>
);

export default Filter;
