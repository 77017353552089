import AnalysisAndReporting, { useAnalysisAndReporting } from '@qualitas/reporting';

const PortfolioDetailsReporting = (props: any) => {
  const { api } = props;

  const queryString = window.location.search;
	const allParams = new URLSearchParams(queryString);
	let searchParams = allParams?.get('report');

  const { ...reportingPropsPortfolioDetail } = useAnalysisAndReporting({
		api,
		showAll: false,
		hasMenu: true,
		searchParams,
    appName: "Track Wizard",
  });

  return (
    <AnalysisAndReporting
      {...reportingPropsPortfolioDetail}
      hasMenu
      filters={[
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "DrawdownPeriodoActual",
            column: "FQualitas",
          },
          operator: "eq",
          values: ["KIWAYU"],
        },
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "DrawdownPeriodoAnterior",
            column: "FQualitas",
          },
          operator: "eq",
          values: ["KIWAYU"],
        },
      ]}
    />
  );
};

export default PortfolioDetailsReporting;
